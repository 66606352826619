*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  color: #777;
  background: #f7f7f7;
}

@media screen and (max-width: 700px) {
  html {
    font-size: 59%;
  }
}
