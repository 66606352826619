.Card {
  border: 3px solid #eff5ff;
  min-width: 15rem;
  width: 55rem;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.Card-header {
  padding: 0.5rem;
  background: #eff5ff;
  display: flex;
  justify-content: space-between;
}

.Card-header-title {
  color: #247ba0;
  font-weight: bold;
}

.Card-header-circles span {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  margin: 3px;
  border-radius: 50%;
}

.circle-1 {
  background: #f28779;
}

.circle-2 {
  background: #fc6;
}

.circle-3 {
  background: #7ed56f;
}

@media screen and (max-width: 700px) {
  .Card {
    width: 95%;
  }
}
