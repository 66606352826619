*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  color: #777;
  background: #f7f7f7;
}

@media screen and (max-width: 700px) {
  html {
    font-size: 59%;
  }
}

.App-title {
  text-align: center;
  font-size: 4rem;
  letter-spacing: 3px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 13px 18px rgba(0, 0, 0, 0.4);
  margin: 0;
  padding: 3rem 0;
  background: #247ba0;
  color: #eff5ff;
  text-transform: uppercase;
}

.App-content {
  display: flex;
  justify-content: space-around;
}

#editor {
  resize: none;
  width: 100%;
  height: 70vh;
  display: block;
  overflow-y: auto;
  padding: 1rem;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: #777;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
}

#preview {
  overflow-wrap: break-word;
  overflow-y: auto;
  padding: 1rem;
  height: 70vh;
  background: #fff;
}

/* markdown styles */
#preview h1,
#preview h2 {
  border-bottom: 1px solid #ccc;
}

#preview code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background: #d8e6ff;
  padding: 0 3px;
  color: #555;
  border-radius: 3px;
}

#preview pre {
  background: #d8e6ff;
  padding: 5px 0 5px 5px;
  line-height: 1.45;
  overflow: auto;
  color: #555;
  border-radius: 3px;
}

#preview blockquote {
  border-left: 3px solid #d8e6ff;
  padding: 0 1rem;
}

#preview table {
  border-collapse: collapse;
}

#preview tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

#preview td,
#preview th {
  border: 1px solid #ccc;
  padding: 0.6rem 1.3rem;
}

@media screen and (max-width: 700px) {
  .App-content {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  #editor,
  #preview {
    max-height: 40vh;
  }
}

.Card {
  border: 3px solid #eff5ff;
  min-width: 15rem;
  width: 55rem;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.Card-header {
  padding: 0.5rem;
  background: #eff5ff;
  display: flex;
  justify-content: space-between;
}

.Card-header-title {
  color: #247ba0;
  font-weight: bold;
}

.Card-header-circles span {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  margin: 3px;
  border-radius: 50%;
}

.circle-1 {
  background: #f28779;
}

.circle-2 {
  background: #fc6;
}

.circle-3 {
  background: #7ed56f;
}

@media screen and (max-width: 700px) {
  .Card {
    width: 95%;
  }
}

.Footer {
  text-align: center;
  background: #247ba0;
  padding: 1.2rem 0;
  color: #eff5ff;
  font-size: 1.4rem;
}

.Footer-link {
  text-decoration: none;
  color: #f1c40f;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-out;
}

.Footer-link:hover {
  color: #ccc;
  border-bottom: 1px solid #f1c40f;
}

